nav ul {
     list-style:none;     
     padding:0px;
     margin:0px;
     z-index:30;
}
nav ul li{
     display: inline-block;
     padding:0px;
     position:relative;
}
nav ul li ul{
     display:none;
     position:absolute;
     background-color: salmon;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
nav ul li ul li{
     display: block; 
     padding:20px;    
}
nav ul li:hover ul{
     display:block;     
}
nav ul li ul li:hover{
     background-color: lightsalmon;
}
