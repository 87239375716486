.font{
    font-family:Baskerville;
    color:black;
}
.font10{
    font-size:10px;
}
.font12{
    font-size:12px;
}
.font14{
    font-size:14px;
}
.font16{
    font-size:16px;
}
.font18{
    font-size:18px;
}
.font20{
    font-size:20px;
}
.font22{
    font-size:22px;
}
.font24{
    font-size:24px;
}
.font26{
    font-size:26px;
}
.font28{
    font-size:28px;
}
.font0{
    font-size:0px;
}

h1{ 
    font-family:Baskerville;
    font-size:32px;
    font-weight:bold;
    /*text-align: center;*/
    color:black;
}
h2{
    font-family:Baskerville;
    font-size:28px;
    font-weight:bold;
    /*text-align:left;*/
    color:black;
    /*text-shadow: 1px 1px 2px #000000;*/

}
h3{ 
    font-family:Baskerville;
    font-size:24px;
    font-weight:bold;
    /*text-align:left;*/
    color:black;
    /*text-shadow: 1px 1px 2px slategrey;*/
}

h4{ 
    font-family:Baskerville;
    font-size:20px;
    font-weight:bold;
    /*text-align:left;*/
    color:black;
    /*text-shadow: 2px 2px 4px slategrey;*/
}
h5{ 
    font-family:Baskerville;
    font-size:20px;
    font-weight:bold;
    /*text-align:center;*/
    color:black;
    /*text-shadow: 2px 2px 4px slategrey;*/
}
